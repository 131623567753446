@font-face {
  font-family: 'Century Gothic';
  src: url('font/Century\ Gothic.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Century Gothic';
  src: url('font/Century\ Gothic\ Bold.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
}
:root {
  --clr-background: #ded5c7;
  --clr-dark: #865942;
  --clr-light: #e9e3df;
}
html {
  font-family: 'Century Gothic';
}
th {
  font-weight: 400;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
body {
  background-color: var(--clr-background);
  margin: 15px;
}
.vehicleImg {
  display: block;
  margin: 12px auto 0;
}
.tram .vehicleImg {
  width: 28px;
}
.train .vehicleImg {
  width: 33px;
}
.arrowImg {
  display: block;
  margin: auto;
  width: 23px;
}
main {
  display: grid;
  grid-template: ' tram train ' / 1fr 1fr;
  gap: 15px;
}
@media only screen and (max-width: 900px) {
  main {
    grid-template: ' tram ' ' train ';
  }
}
.tram {
  grid-area: tram;
  height: 514px;
  background-color: rgb(133,89,66);
}
.train {
  grid-area: train;
  height: 514px;
  background-color: rgb(133,89,66);
}
.table {
  border-spacing: initial;
  width: 100%;
}
.table__header {
  background-color: var(--clr-dark);
  color: var(--clr-background);
  text-align: left;
  padding-top: 12px;
  font-size: 13px;
}
.table__header--bold {
  font-weight: 700;
  font-size: 30px;
}
.table__header--bigger {
  font-size: 20px;
}
.table__row:nth-child(odd) {
  background-color: var(--clr-light);
}
.table__row--train:nth-child(even) {
  background-color: var(--clr-light);
}
.table__row--train:nth-child(odd) {
  background-color: var(--clr-background);
}
.table__row--tram:nth-child(even) {
  background-color: var(--clr-light);
}
.table__row--tram:nth-child(odd) {
  background-color: var(--clr-background);
}
.table__cell--center {
  text-align: center;
}
.weather {
  background-color: var(--clr-dark);
  color: var(--clr-light);
  display: grid;
  grid-auto-flow: column;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}
.weather__day {
  display: grid;
  grid-template: ' date1 forecast1 ' ' date2 forecast1 ' ' date3 forecast2 ' / auto auto;
  place-items: center;
}
.weather__date--1 {
  grid-area: date1;
}
.weather__date--2 {
  grid-area: date2;
}
.weather__date--3 {
  grid-area: date3;
}
.weather__date--today {
  font-size: 22px;
}
.weather__date--nextDay {
  font-size: 12px;
}
.weather__date--nextDay:first-of-type {
  align-self: end;
}
.weather__forecast--1 {
  grid-area: forecast1;
}
.weather__forecast--2 {
  grid-area: forecast2;
}

td {
  height: 22px;
}

.last--header {
  padding-top: 5px;
  padding-bottom: 11px;
}

.tram-invisible-text {
  color: rgba(0,0,0,0);
}